import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Grid, Typography, IconButton, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { GroupWork, CloudDownload, SystemUpdateAlt } from "@material-ui/icons";

import {
  loadCollectionAttribute,
  collectionActions,
} from "../../../reducers/collectionsReducer";
import ArticleSelect from "../../common/Components/ArticleSelect";
import CollectionCrud from "../../common/Components/CollectionCrud";
import NumberInput from "../../common/Components/NumberInput";

import LotCss from "./css/LotMadNegoceCss";
import StockMatierePremiere from "../Stock/MatierePremiere";
import TextInput from "../../common/Components/TextInput";
import DocumentPicker from "../../common/Components/DocumentPicker";

class LotMadNegoce extends Component {
  static defaultProps = {
    delete: true,
    pagination: true,
    search: true,
  };

  static propTypes = {
    actionsCallback: PropTypes.func,
    mise_a_disposition_negoce_uuid: PropTypes.string,
    createUpdateModalSubmit: PropTypes.string,
    delete: PropTypes.bool,
    modal: PropTypes.object,
    pagination: PropTypes.bool,
    search: PropTypes.bool,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { pirPcrsStore } = nextProps;

    if (!pirPcrsStore.list) {
      return null;
    }
    return {
      default_pir_pcr_uuid: pirPcrsStore.list.find((e) => e.slug === "pcr")
        .uuid,
    };
  }

  state = {
    currentLot: null,
    openStockModal: false,
    default_pir_pcr_uuid: null,
  };

  componentDidMount() {
    collectionActions(this.props.dispatch, "articles", "INDEX", {
      params: {
        with_trahsed_affaire: true,
      },
    });
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "pir-pcrs",
      this.props.pirPcrsStore
    );
  }

  getDeleteModalTitle() {
    return (
      <div>
        <GroupWork />
        <span>{"Suppression de l'article"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cet article ?"}
      </Typography>
    );
  }

  handleLotModalActions(action, stock) {
    if (action === "close") this.onCloseStockHandler();
    if (action === "create" || action === "update") {
      collectionActions(this.props.dispatch, "affaires", "SHOW", {
        uuid: this.props.affaireUuid,
      });
    }
  }

  getCreateUpdateModalTitle(lotMadNegoce) {
    let title =
      !!lotMadNegoce && !!lotMadNegoce.article
        ? "Modification de l'article : " + lotMadNegoce.article.nom
        : "Ajout d'un nouvel article";
    return (
      <div>
        <CloudDownload />
        <span>{title}</span>
      </div>
    );
  }

  getPirPcrs() {
    if (!this.props.pirPcrsStore.list) {
      return [];
    }
    return this.props.pirPcrsStore.list.map((pir) => {
      return (
        <MenuItem value={pir.uuid} key={pir.uuid}>
          {pir.libelle}
        </MenuItem>
      );
    });
  }

  // getDocumentBtn(articleLot) {
  //   const { classes } = this.props;
  //   return (
  //     <DocumentPicker
  //       documentable_uuid={articleLot.uuid}
  //       documentable_type="App\Models\LotMadNegoce"
  //       className={classes.documentBtn}
  //       hasDocument={articleLot.has_document}
  //       restrictDocumentType={["is_lot_mad_negoce"]}
  //     />
  //   );
  // }

  getCreateUpdateModalContent(
    lotMadNegoce,
    lotMadNegoceStore,
    onChangeHandler
  ) {
    // Initialise les propriétés qui ne sont pas dans l'objet natif
    for (let prop of ["famille_uuid"]) {
      if (lotMadNegoce.hasOwnProperty("article")) {
        lotMadNegoce[prop] = lotMadNegoce["article"][prop];
      } else if (!lotMadNegoce.hasOwnProperty(prop)) {
        lotMadNegoce[prop] = null;
      }
    }

    // definir le pir_pcr_uuid par defaut si en création
    if (!lotMadNegoce.uuid && !lotMadNegoce.pir_pcr_uuid) {
      lotMadNegoce.pir_pcr_uuid = this.state.default_pir_pcr_uuid;
    }

    const classes = this.props.classes;

    let {
      article,
      conditionnement_uuid,
      pir_pcr_uuid,
      poids_to,
      prix_to,
      broye,
      mfi,
      couleur,
      analyse_dispo,
      risque_pollution,
      commentaire,
    } = lotMadNegoce;
    return (
      <Grid container>
        <Grid item xs={6}>
          <TextInput
            id="conditionnement_uuid"
            label="Conditionnement"
            value={conditionnement_uuid}
            required={true}
            margin="normal"
            collectionStore={lotMadNegoceStore}
            name="conditionnement_uuid"
            onChangeHandler={onChangeHandler}
            select
            style={{ margin: "0 0 0 5px", width: "calc(100% - 5px)" }}
          >
            {this.getConditionnements()}
          </TextInput>
        </Grid>
        <ArticleSelect
          onChangeHandler={onChangeHandler.bind(this)}
          store={lotMadNegoceStore}
          famille_uuid={article ? article.famille_uuid : null}
          article_uuid={article ? article.uuid : null}
          familleStyle={{ margin: "0 5px 0 0", width: "calc(100% - 5px)" }}
        />

        <Grid item xs={6}>
          <TextInput
            id="pir_pcr_uuid"
            label="PIR/PCR"
            value={pir_pcr_uuid}
            className={classes.selectContainer}
            margin="normal"
            collectionStore={lotMadNegoceStore}
            name="pir_pcr_uuid"
            onChangeHandler={onChangeHandler}
            select
          >
            {this.getPirPcrs()}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            id="poids_to"
            label="Poids (t)"
            value={poids_to}
            margin="normal"
            collectionStore={lotMadNegoceStore}
            name="poids_to"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            id="prix_to"
            label="Prix (€/t)"
            value={prix_to}
            margin="normal"
            collectionStore={lotMadNegoceStore}
            name="prix_to"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="broye"
            label="Broyé"
            value={broye}
            margin="normal"
            collectionStore={lotMadNegoceStore}
            name="broye"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
          >
            <MenuItem value="broye">Broyé</MenuItem>
            <MenuItem value="en_etat">En l'état</MenuItem>
            <MenuItem value="granule">Granulé</MenuItem>
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="mfi"
            label="MFI"
            value={mfi}
            margin="normal"
            collectionStore={lotMadNegoceStore}
            name="mfi"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="couleur"
            label="Couleur"
            value={couleur}
            margin="normal"
            collectionStore={lotMadNegoceStore}
            name="couleur"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="analyse_dispo"
            label="Analyse dispo"
            value={analyse_dispo}
            margin="normal"
            collectionStore={lotMadNegoceStore}
            name="analyse_dispo"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="risque_pollution"
            label="Risque pollution"
            value={risque_pollution}
            margin="normal"
            collectionStore={lotMadNegoceStore}
            name="risque_pollution"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <label htmlFor="commentaire" style={{ marginLeft: "5px" }}>
            Commentaire
          </label>
          <textarea
            id="commentaire"
            onChange={(e) => onChangeHandler("commentaire", e)}
            rows="6"
            style={{ marginLeft: "5px", width: "99%" }}
            placeholder="Commentaire"
            value={commentaire}
          ></textarea>
        </Grid>
      </Grid>
    );
  }

  getConditionnements() {
    if (!this.props.conditionnementsStore.list) {
      return [];
    }
    return this.props.conditionnementsStore.list.map((conditionnement) => {
      return (
        <MenuItem value={conditionnement.uuid} key={conditionnement.uuid}>
          {conditionnement.nom}
        </MenuItem>
      );
    });
  }

  formatPoids(poids, nbDecimals = 3) {
    if (!poids) {
      return "";
    }
    const inf = new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: nbDecimals,
      maximumFractionDigits: nbDecimals,
    });
    return inf.format(poids);
  }

  formatPrix(prix) {
    if (!prix) {
      return "";
    }
    const inf = new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return inf.format(prix);
  }

  getCommentaireTd(commentaire, length = 20) {
    if (!commentaire) return null;
    return (
      <span
        title={commentaire}
        style={{
          cursor: "help",
        }}
      >
        {commentaire.substring(0, length)}
        {commentaire.length > length ? "..." : ""}
      </span>
    );
  }

  getCellsConfig() {
    let { labelPrix, classes } = this.props;
    let config = [];

    config.push(
      {
        datakey: "article_famille_nom",
        title: "Famille",
        sortable: true,
        searchable: true,
        className: classes.noBackgroundColor,
      },
      {
        datakey: "article_nom",
        title: "Article",
        sortable: true,
        searchable: true,
        className: classes.noBackgroundColor,
      },
      {
        datakey: "conditionnement_nom",
        title: "Conditionnement",
        sortable: true,
        searchable: true,
        className: classes.conditionnement,
      },
      // {
      //   datakey: "pir_pcr_libelle",
      //   title: "PIR/PCR",
      //   sortable: true,
      //   searchable: true,
      //   className: classes.noBackgroundColor,
      // },
      // {
      //   datakey: "poids_to",
      //   title: "Poids (t)",
      //   sortable: true,
      //   searchable: true,
      //   format: (obj, key) => {
      //     return this.formatPoids(obj[key]);
      //   },
      //   className: classes.noBackgroundColor,
      // },
      // {
      //   datakey: "prix_to",
      //   title: labelPrix,
      //   sortable: true,
      //   searchable: true,
      //   format: (obj, key) => {
      //     return this.formatPrix(obj[key]);
      //   },
      //   className: classes.noBackgroundColor,
      // },
      // {
      //   datakey: "broye",
      //   title: "Etat",
      //   sortable: true,
      //   searchable: true,
      //   className: classes.noBackgroundColor,
      // },
      // {
      //   datakey: "mfi",
      //   title: "MFI",
      //   sortable: true,
      //   searchable: true,
      // },
      // {
      //   datakey: "couleur",
      //   title: "Couleur",
      //   sortable: true,
      //   searchable: true,
      // },
      // {
      //   datakey: "analyse_dispo",
      //   title: "Analyse dispo",
      //   sortable: true,
      //   searchable: true,
      // },
      // {
      //   datakey: "risque_pollution",
      //   title: "Risque pollution",
      //   sortable: true,
      //   searchable: true,
      // },
      // {
      //   datakey: "commentaire",
      //   title: "Commentaires",
      //   sortable: true,
      //   searchable: true,
      //   format: (data, key) => {
      //     return this.getCommentaireTd(data[key], 20);
      //   },
      //   className: classes.noBackgroundColor,
      // }
    );

    return config;
  }

  getCrudTitle() {
    const { classes } = this.props;

    return (
      <>
        <Typography variant="button" gutterBottom className={classes.crudTitle}>
          Articles
        </Typography>
      </>
    );
  }

  getExtraDataForm() {
    let { mise_a_disposition_negoce_uuid } = this.props;
    if (mise_a_disposition_negoce_uuid) {
      return {
        mise_a_disposition_negoce_uuid: mise_a_disposition_negoce_uuid,
      };
    }
  }

  render() {
    const {
      actionsCallback,
      createUpdateModalSubmit,
      createUpdateModalNext,
      modal,
      datas,
      disable_edit_add,
      classes,
    } = this.props;

    return (
      <>
        <CollectionCrud
          collectionName={"lot-mad-negoces"}
          showBtnEdit={true}
          showBtnAdd={true}
          rights={
            {
              // create: "admin-cud-affaires",
              // edit: "admin-cud-affaires",
              // delete: "admin-cud-affaires",
            }
          }
          datas={datas}
          loadDatas={false}
          showBtnDelete={disable_edit_add ? false : this.props.delete}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent.bind(this)}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          createUpdateModalSubmit={createUpdateModalSubmit}
          createUpdateModalNext={createUpdateModalNext}
          extradatasForm={this.getExtraDataForm()}
          datatableConfig={{
            showPagination: this.props.pagination,
            showSearch: this.props.search,
            // defaultSort: "article.nom",
            // sortType: "desc",
            // nodatalabel: "Aucun article",
          }}
          dataTableExtraNodes={[
            {
              element: this.getCrudTitle(),
              position: "top-left",
            },
          ]}
          cellsConfig={this.getCellsConfig()}
          actionsCallback={actionsCallback}
          // additionnalControllers={[this.getDocumentBtn.bind(this)]}
          modal={modal}
          defaultValues={{
            pir_pcr_uuid: this.state.default_pir_pcr_uuid,
          }}
        />
      </>
    );
  }
}

LotMadNegoce = withStyles(LotCss)(LotMadNegoce);

LotMadNegoce = connect((store) => {
  return {
    articlesStore: store.collections.articles,
    conditionnementsStore: store.collections.conditionnements,
    famillesStore: store.collections.familles,
    pirPcrsStore: store.collections["pir-pcrs"],
    lotMadNegoceStore: store.collections["lot-mad-negoces"],
  };
})(LotMadNegoce);

export default LotMadNegoce;
