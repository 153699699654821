import {
  Fab,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  Input,
  Select
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { Add, MoreHoriz, Person, VerticalAlignBottom, SystemUpdateAlt } from "@material-ui/icons";
import format from "date-fns/format";
import React, { Component } from "react";

import { connect } from "react-redux";
import {
  collectionActions,
} from "../../../reducers/collectionsReducer";
import { buildRoute } from "../../../router/Tools";

import CollectionCrud from "../../common/Components/CollectionCrud";
import { getStatuts } from "./actions/PackinglistActions";

import PackinglistCss from "./css/PackinglistCss";
import { getFromLocalStorage, storeToLocalStorage,getDate } from "../../tools/Tools";


const LOCAL_STORAGE_KEY = "PACKING_LIST_FILTERS";
class Packinglist extends Component {
  state = {
    produitsInitialLoad: false,
    statuts: null,
    filter_sep: "all",
    filter_status: ["disponible","en-cours","stockage-ext","pret-a-partir"],
    local_async_filter: null,
    local_storage_key: LOCAL_STORAGE_KEY,
  };

  componentDidMount() {
    getStatuts((statuts) => {
      this.setState({
        statuts,
      });
    });
    collectionActions(
      this.props.dispatch,
      "stock/produits_transformes",
      "RESET_LIST"
    );

    const { local_storage_key } = this.props;
    if (local_storage_key) {
      this.setState({ local_storage_key: local_storage_key });
    }
    let filter_localStorage = getFromLocalStorage(
      local_storage_key ? local_storage_key : LOCAL_STORAGE_KEY
    );
    if (filter_localStorage) {
      this.setState(filter_localStorage, () => {
        this.loadAsyncData(this.state.current_filter, true);
      });
    } else {
      this.loadAsyncData(this.state.local_async_filter, true);
    }
  }


  getDeleteModalTitle() {
    return (
      <div>
        <Person />
        <span>{"Suppression de la packing list"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette packing list ?"}
      </Typography>
    );
  }

  getDate(string) {
    if (!string) {
      return "";
    }
    let date = new Date(string);
    let options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    return date.toLocaleDateString("fr-FR", options);
  }

  getCellsConfig() {
    const { classes } = this.props;

    return [
      {
        datakey: "reference",
        title: "Référence",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "user.firstname",
        title: "Utilisateur",
        sortable: true,
        searchable: true,
        format: (packinglist) =>
          packinglist.user.firstname + " " + packinglist.user.lastname,
      },
      {
        datakey: "statut",
        title: "Statut",
        sortable: true,
        searchable: true,
        format: (packinglist) =>
          this.state.statuts[
            Object.keys(this.state.statuts).find(
              (statut) => statut === packinglist.statut
            )
          ],
      },
      {
        datakey: "affaire_reference_interne",
        title: "Affaire",
        sortable: true,
        searchable: true,
        format: (packinglist) => {
          return (
            <span
              className={classes.link}
              onClick={() =>
                this.props.history.push(
                  buildRoute("/affaires/:uuid", {
                    uuid: packinglist.affaire_uuid,
                  })
                )
              }
            >
              {packinglist.affaire
                ? packinglist.affaire.reference_interne
                : null}
            </span>
          );
        },
      },
      {
        datakey: "affaire_date_enlevement",
        title: "Date d'enlevement",
        sortable: true,
        searchable: false,
        isDate: true,
          format: (obj, key) => {
            return getDate(obj.affaire?.date_enlevement);
          },
      },
      {
        datakey: "nb_bb",
        title: "NB BB",
        sortable: false,
        searchable: true,
      },
      {
        datakey: "poids",
        title: "Poids kg",
        sortable: false,
        searchable: true,
      },
      {
        datakey: "observations",
        title: "Observations",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "commentaire_interne",
        title: "Commentaire interne",
        sortable: false,
        searchable: true,
      },
      {
        datakey: "is_sep",
        title: "SEP",
        sortable: true,
        searchable: true,
        format: (packinglist) => {
          return packinglist.is_sep ? "Oui" : "Non";
        },
      },
      {
        datakey: "date_sortie",
        title: "Date de sortie",
        sortable: true,
        searchable: true,
        isDate: true,
        format: (obj, key) => {
          return this.getDate(obj[key]);
        },
      },
    ];
  }

  getCrudTitle() {
    const { classes } = this.props;
    return [
      <Typography
        key="typo"
        variant="button"
        gutterBottom
        className={classes.crudTitle}
      >
        Packing lists
      </Typography>,
    ];
  }

  getDownloadButton(packinglist) {
    const { classes } = this.props;

    return (
      <IconButton
        key={packinglist.uuid}
        title={"Télécharger la packing list"}
        variant="contained"
        color="primary"
        href={
          process.env.REACT_APP_API_URL +
          "/packinglists/" +
          packinglist.uuid +
          "/pdf"
        }
        className={classes.button}
        target="_blank"
      >
        <VerticalAlignBottom />
      </IconButton>
    );
  }

  getDownloadPLInterneButton(packinglist) {
    const { classes } = this.props;

    return (
      <IconButton
        key={packinglist.uuid}
        title={"Export PL Exploitation - ne pas envoyer au client"}
        variant="contained"
        color="warning"
        href={
          process.env.REACT_APP_API_URL +
          "/packinglists/" +
          packinglist.uuid +
          "/exploitation-pdf"
        }
        className={classes.button}
        target="_blank"
      >
        <SystemUpdateAlt />
      </IconButton>
    );
  }

  isAdminOrSuperAdmin() {
    const { user } = this.props;
    return user.is_admin;
  }

  allowEditDelete(packinglist) {
    if (this.isAdminOrSuperAdmin()) return true;

    return packinglist.statut !== "vendu";
  }

  getDetailButton(packinglist) {
    if (!this.allowEditDelete(packinglist)) return null;

    const { classes } = this.props;
    return (
      <IconButton
        key="detail"
        aria-label="Afficher"
        color="primary"
        onClick={() => {
          this.props.history.push(
            buildRoute("/pslists/packinglists/:uuid", {
              uuid: packinglist.uuid,
            })
          );
        }}
        title="Afficher"
        className={classes.button}
      >
        <MoreHoriz />
      </IconButton>
    );
  }

  getCreateBtn() {
    const { classes } = this.props;
    return (
      <Fab
        size="small"
        color="primary"
        aria-label="add"
        onClick={() => {
          this.props.history.push("/pslists/packinglists/create");
        }}
        className={classes.buttonAdd}
        title="Ajouter"
      >
        <Add style={{ fontSize: 32 }} />
      </Fab>
    );
  }

  getFilters() {
    const { classes } = this.props;
    const { filter_sep, filter_status } = this.state;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="filter_sep"
            label="SEP"
            value={filter_sep}
            margin="normal"
            onChange={this.onChangeHandlerFilters.bind(this, "filter_sep")}
            className={classes.selectContainer}
            select
          >
            <MenuItem value={"all"}>Tous</MenuItem>
            <MenuItem value={"oui"}>Oui</MenuItem>
            <MenuItem value={"non"}>Non</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={4} className={classes.multiSelectContainer}>
          <InputLabel id="filter_status" className={classes.filterStatusLabel}>STATUT</InputLabel>
          <Select
            labelId="filter_status"
            label="STATUT"
            value={filter_status}
            input={<Input id="select-multiple-type" />}
            margin="normal"
            onChange={this.onChangeHandlerStatusFilters.bind(this, "filter_status")}
            className={classes.selectContainer}
            select
            multiple
          >
            <MenuItem value={"all"}>Tous</MenuItem>
            <MenuItem value={"disponible"}>Disponible</MenuItem>
            <MenuItem value={"en-cours"}>En cours</MenuItem>
            <MenuItem value={"vendu"}>Vendu</MenuItem>
            {/* <MenuItem value={"non-vendu"}>Non vendu</MenuItem> */}
            <MenuItem value={"stockage-ext"}>Stockage ext</MenuItem>
            <MenuItem value={"pret-a-partir"}>Pret a partir</MenuItem>
          </Select>
        </Grid>
      </Grid>
    );
  }

  onChangeHandlerStatusFilters(name, event) {
    let values = event.target.value;
    /**
     * Gestion de la value all
     * si on clique dessus on vide la liste et ne garde que all
     * si on clique sur un autre element on enleve all
     * si on vide la liste on rajoute all
     */

    if ( values.length > 0) {
      if (this.state[name].indexOf("all") !== -1) {
        values = values.filter((value) => value !== "all");
      } else if (values.indexOf("all") !== -1) {
        values = ["all"];
      } else if(name === 'filter_status' && values.includes('non-vendu')){
        values = ["non-vendu"];
      }
    } else {
      values = ["all"];
    }

    this.setState({ [name]: values }, () => {
      storeToLocalStorage(
        this.state.local_storage_key,
        {
          filter_sep: this.state.filter_sep,
          filter_status: this.state.filter_status,
        },
        60 * 12
      );
      this.loadAsyncData(this.state.local_async_filter);
    });
  }

  onChangeHandlerFilters(name, event) {
    let values = event.target.value;
    this.setState({ [name]: values }, () => {
      storeToLocalStorage(
        this.state.local_storage_key,
        {
          filter_sep: this.state.filter_sep,
          filter_status: this.state.filter_status,
        },
        60 * 12
      );
      this.loadAsyncData(this.state.local_async_filter);
    });
  }

  loadAsyncData(filters) {
    const { dispatch, packinglistsStore } = this.props;

    if (packinglistsStore.fetching) return;

    const { filter_sep, filter_status } = this.state;

    let local_filter = {
      params: {
        filter_sep,
        filter_status,
        ...filters,
      },
    };

    // eslint-disable-next-line
    this.state.local_async_filter = { ...filters };

    return collectionActions(
      dispatch,
      "packinglists",
      "INDEX",
      local_filter,
      null,
      true
    );
  }

  render() {
    const { datas } = this.props;
    if (!this.state.statuts) return <CircularProgress size={28} />;

    return (
      <>
        {this.getFilters()}
        <CollectionCrud
          fullScreenModal
          modalMaxWidth="lg"
          collectionName={"packinglists"}
          showBtnEdit={false}
          showBtnAdd={false}
          showBtnDelete={true}
          additionnalControllers={[
            this.getDownloadPLInterneButton.bind(this),
            this.getDownloadButton.bind(this),
            this.getDetailButton.bind(this),
          ]}
          showBtnDeleteCallBack={this.allowEditDelete.bind(this)}
          datas={datas}
          asynchroneCallbackLoadData={this.loadAsyncData.bind(this)}
          loadDatas={!!datas ? false : true}
          asynchrone={datas ? false : true}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            nodatalabel: "Aucune packinglist",
            rowsPerPageOptions: [50, 100],
            defaultSort: "reference",
          }}
          persistDatatableOptions={{
            id: "packing_list",
            exipiration_minutes: 60 * 12, // 12 Heures
          }}
          dataTableExtraNodes={[
            {
              element: this.getCrudTitle(),
              position: "top-left",
            },
            {
              element: this.getCreateBtn(),
              position: "top-right",
            },
          ]}
          cellsConfig={this.getCellsConfig()}
          defaultValues={{
            statut: "disponible",
            date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          }}
        />
      </>
    );
  }
}

Packinglist = withStyles(PackinglistCss)(Packinglist);

Packinglist = connect((store) => {
  return {
    packinglistsStore: store.collections.packinglists,
    user: store.auth.user,
  };
})(Packinglist);

export default Packinglist;
