import {
  Avatar,
  Checkbox,
  Chip,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
  Typography,
  withStyles,
  Button,
} from "@material-ui/core";
import {
  BarChart,
  CheckCircle,
  Settings,
  Warning,
  Print,
  FileCopy,
  Check,
  History,
  Restore,
  WhereToVote,
} from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  collectionActions,
  loadCollectionAttribute,
  masseImportBigBags,
  validateBigBag,
  getProduitTransformeReference,
  remiseEnStockBigBag,
  errorHandlerCollectionCrud,
} from "../../../reducers/collectionsReducer";
import CheckboxInput from "../../common/Components/CheckboxInput";
import CollectionCrud from "../../common/Components/CollectionCrud";
import DateInput from "../../common/Components/DateInput";
import TextInput from "../../common/Components/TextInput";
import {
  getProduitsTransformesEtat,
  getProduitsTransformesOptions,
  getProduitsTransformesOrigines,
  getUsers,
} from "./actions/StockActions";
import StockCss from "./css/StockCss";
import ProduitTransformeEtat from "./ProduitTransformeEtat";
import { REF_PREFIX as ORIGINES_PREFIX } from "../ProduitsOrigines";
import { hasRights } from "../../common/Tools/Tools";
import { buildRoute } from "../../../router/Tools";
import { getApiUrl } from "../../tools/Tools";
import {
  addNotification,
  axiosErrorHandler,
} from "../../../reducers/notificationReducer";
import { themeComplement } from "../AppAdmin/css/theme";
import { axiosClient } from "../../../axios";
import DocumentPicker from "../../common/Components/DocumentPicker";

const initialState = {
  ccrudProduitTransforme: null,
  article_uuid: null,
  currentProduitUuid: null,
  entame: false,
  etat: null,
  incomplet: false,
  isReset: false,
  famille_uuid: null,
  matieres_premieres: [],
  production: null,
  origines_filtre: [],
  produits_origines_disabled: false,
  produits_origines_uuid: null,
  openStats: false,
  reset_field: false,
  selectedPoidsConsomme: 0,
  usersList: [],
  users_uuids: [],
  filterReserved: ["all"],
  origine_groupe_filtre: ["all"],
  famille_filtre: ["all"],
  filter_stockage_exertieur: "all",
  filter_commentaire_carriste: "all",
  options: {},
  autoPrint: false,
  local_async_filter: null,
  bb_entames: null,
  produit_transforme_uuid: null,
  defaultValues: {
    separateur_metaux: false,
    flocage: "CAPECO",
    pir_pcr_uuid: null,
  },
};

class ProduitTransforme extends Component {
  state = initialState;

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const { produitsTransformesStore, pirPcrsStore } = nextProps;

    if (
      produitsTransformesStore.detail &&
      prevState.currentProduitUuid !== produitsTransformesStore.detail.uuid &&
      produitsTransformesStore.detail.article
    ) {
      return {
        currentProduitUuid: produitsTransformesStore.detail.uuid,
        users_uuids: produitsTransformesStore.detail.users_uuids,
        isReset: false,
        famille_uuid: produitsTransformesStore.detail.article.famille_uuid,
        produits_origines_uuid:
          produitsTransformesStore.detail.produits_origines_uuid,
      };
    } else if (produitsTransformesStore.detail === null && !prevState.isReset) {
      return {
        article_uuid: null,
        currentProduitUuid: null,
        entame: false,
        famille_uuid: null,
        incomplet: false,
        isReset: true,
        produits_origines_uuid: null,
        users_uuids: [],
      };
    }

    if (pirPcrsStore.list) {
      return {
        defaultValues: {
          ...prevState.defaultValues,
          pir_pcr_uuid: pirPcrsStore.list.find((e) => e.slug === "pcr")
            .uuid,
        },
      };
    }
    return null;
  }

  /**
   * Lors du montage du composant
   */
  componentDidMount() {
    getUsers().then((users) => {
      if (users.length > 0) this.setState({ usersList: users });
    });

    getProduitsTransformesOrigines().then((origines) => {
      this.setState({ origines_filtre: origines });
    });

    getProduitsTransformesEtat().then((etat) => {
      this.setState({ etat: etat });
    });

    getProduitsTransformesOptions().then((options) => {
      this.setState({ options: options });
    });

    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "produits_origines",
      this.props.produitsOriginesStore
    );
    collectionActions(this.props.dispatch, "articles", "INDEX", {
      params: {
        broye_only: true,
      },
    });
    collectionActions(this.props.dispatch, "familles", "INDEX", {
      params: {
        broye_only: true,
      },
    });
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "pir-pcrs",
      this.props.pirPcrsStore
    );
  }

  componentWillUnmount() {
    let { dispatch } = this.props;
    collectionActions(dispatch, "users", "RESET_ERRORS");
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression du produit transformé"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer ce produit transformé ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(produitTransforme) {
    let title = !!produitTransforme
      ? "Modification du produit transformé : " + produitTransforme.reference
      : "Ajout d'un nouveau produit transformé";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  /**
   * Met à jour lors d'un appui sur la touche ENTER dans le
   * formulaire des champs.
   */
  onKeyPressDateHandler(event, handler) {
    if (event.which === 13 || event.keyCode === 13 || event.key === "Enter") {
      handler();
    }
  }

  /**
   * Handler de gestion du state du select Multiple
   */
  onChangeMultipleSelectHandler(e) {
    this.setState({
      users_uuids: e.target.value,
    });
  }

  /**
   * Handler de gestion du delete sur les Chips
   * @param stateKey
   * @param uuid
   */
  handleDeleteChip(stateKey, uuid) {
    let array = [...this.state[stateKey]];
    let index = array.findIndex((matiere) => matiere.uuid === uuid);

    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ [stateKey]: array });
    }
  }

  getOrigines() {
    if (!this.props.produitsOriginesStore.list) return [];

    return this.props.produitsOriginesStore.list.map((origine) => {
      return (
        <MenuItem value={origine.uuid} key={origine.uuid}>
          {origine.libelle}
        </MenuItem>
      );
    });
  }

  getFamilles() {
    if (
      !this.props.famillesStore.list ||
      this.props.articlesStore.list === null
    )
      return [];

    return this.props.famillesStore.list.map((famille) => {
      return (
        <MenuItem
          value={famille.uuid}
          key={famille.uuid}
          disabled={
            !this.props.articlesStore.list.find(
              (a) => a.famille_uuid === famille.uuid
            )
          }
        >
          {famille.nom}
        </MenuItem>
      );
    });
  }

  getArticles(famille_uuid) {
    if (!this.props.articlesStore.list) return [];

    let list = this.props.articlesStore.list.slice();

    if (famille_uuid) {
      list = list.filter((a) => a.famille_uuid === famille_uuid);
    }

    return list.map((article) => {
      return (
        <MenuItem value={article.uuid} key={article.uuid}>
          {article.nom}
        </MenuItem>
      );
    });
  }

  onArticleChangeHandler(name, event) {
    this.setState({
      [name]: event.target.value,
    });
  }

  getDate(string) {
    if (!string) {
      return "";
    }
    let date = new Date(string);
    let options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };

    return date.toLocaleDateString("fr-FR", options);
  }

  getCheckBoxComCarristeTraite(obj, key) {
    return (
      <Checkbox
        checked={obj[key]}
        color="primary"
        onChange={(e) => {
          collectionActions(
            this.props.dispatch,
            "stock/produits_transformes",
            "UPDATE",
            {
              ...obj,
              [key]: e.target.checked,
              stock_bb: this.props.stock_bb || false,
            }
          );
        }}
      />
    );
  }

  getCellsConfig(columns) {
    const { out_of_stock, additionnalColumns, sortColumns } = this.props;
    var config = [
      {
        datakey: "reference",
        title: "Référence",
        sortable: true,
        searchable: true,
        format: (obj) => this.getReference(obj),
      },
      {
        datakey: "origine_groupe_filtre",
        title: "Groupe origine",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "pslist_reference",
        title: "P/S list",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "origine_nom",
        title: "Origine",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "pir_pcr_libelle",
        title: "Pir/pcr",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "nom_famille",
        title: "Famille",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "article_nom",
        title: "Article",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "poids",
        title: "Poids (kg)",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "flocage",
        title: "Flocage",
        sortable: true,
        searchable: true,
      },
    ];
    if (!out_of_stock) {
      config = [
        ...config,
        {
          datakey: "separateur_metaux",
          title: "Séparateur",
          sortable: true,
          searchable: false,
          format: (obj) => (obj.separateur_metaux ? <CheckCircle /> : null),
        },
        {
          datakey: "pollution",
          title: "Pollution",
          sortable: true,
          searchable: true,
          format: (obj) => this.getOptionLibelle("pollution", obj),
        },
        {
          datakey: "taux_pollution",
          title: "Taux Pollution",
          sortable: true,
          searchable: true,
          format: (obj) => this.getOptionLibelle("taux_pollution", obj),
        },
        {
          datakey: "poussiere",
          title: "Poussiere",
          sortable: true,
          searchable: true,
          format: (obj) => this.getOptionLibelle("poussiere", obj),
        },
        {
          datakey: "taille_grille",
          title: "Taille Grille",
          sortable: true,
          searchable: true,
          format: (obj) => this.getOptionLibelle("taille_grille", obj),
        },
        {
          datakey: "humidite",
          title: "Humidité",
          sortable: true,
          searchable: true,
          format: (obj) => this.getOptionLibelle("humidite", obj),
        },
        {
          datakey: "valide",
          title: "Valide",
          sortable: true,
          searchable: false,
          format: (obj) => (obj.valide ? <CheckCircle /> : null),
        },
        {
          datakey: "is_on_stock",
          title: "Dans le stock",
          sortable: true,
          searchable: false,
          format: (obj) => (obj.is_on_stock ? <CheckCircle /> : null),
        },
      ];
    } else {
      config = [
        ...config,
        {
          datakey: "date_sortie",
          title: "Date de sortie",
          sortable: true,
          searchable: true,
          format: (obj, key) => {
            return this.getDate(obj[key] || obj["deleted_at"]);
          },
        },
        {
          datakey: "processus_sortie",
          title: "Processus de sortie",
          sortable: true,
          searchable: false,
        },
      ];
    }

    config = [
      ...config,
      {
        datakey: "commentaire",
        title: "Commentaire Client",
        sortable: false,
        searchable: true,
      },
      {
        datakey: "commentaire_interne",
        title: "Commentaire interne",
        sortable: false,
        searchable: true,
      },
      {
        datakey: "commentaire_cariste",
        title: "Commentaire cariste",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "comm_cariste_traite",
        title: "Commentaire cariste traité",
        sortable: true,
        searchable: true,
        useDataKeyOnSearch: true,
        format: this.getCheckBoxComCarristeTraite.bind(this),
      },
      {
        datakey: "date_transformation",
        title: "Date de transformation",
        sortable: true,
        searchable: true,
        isDate: true,
        format: (obj, key) => {
          return this.getDate(obj[key]);
        },
      },
      {
        datakey: "created_at",
        title: "Date de création",
        sortable: true,
        searchable: true,
        isDate: true,
        format: (obj, key) => {
          return this.getDate(obj[key]);
        },
      },
      {
        datakey: "fake",
        title: "Documents",
        sortable: false,
        searchable: false,
        format: (obj, key) => {
          return this.getBtnDocuments(obj);
        },
      },
    ];

    if (additionnalColumns) {
      config = [...config, ...additionnalColumns];
    }

    if (columns) {
      config = config.filter((item) => columns.includes(item.datakey));
      if (sortColumns) {
        config.sort((a, b) => {
          if (columns.includes(a.datakey) && columns.includes(b.datakey)) {
            return columns.indexOf(a.datakey) - columns.indexOf(b.datakey);
          }
          return 0;
        });
      }
    }

    return config;
  }

  getPrintEtiquetteButton(produitTrans) {
    const { classes, user, hideBtnEtiquette } = this.props;
    if (!hasRights("bigbag-impression-etiquette", user) || hideBtnEtiquette)
      return null;

    return (
      <IconButton
        key="print-etiquette"
        onClick={() => {
          window.open(
            buildRoute(getApiUrl() + "/stock/produits_transformes/etiquette", {
              listUuids: JSON.stringify([produitTrans.uuid]),
            }),
            "_blank"
          );
        }}
        aria-label="Imprimer les étiquettes"
        title="Imprimer les étiquettes"
        className={classes.button}
      >
        <Print /*color="primary"*/ />
      </IconButton>
    );
  }

  getLogButton(produitTrans) {
    const { classes, user } = this.props;
    // if (!hasRights("bigbag-impression-etiquette", user) || hideBtnEtiquette)
    //   return null;
    if (!hasRights("export-mouvement-BB", user)) return null ;

    else{
    return (
      <IconButton
        key={"produit-log-"+produitTrans.uuid}
        href={process.env.REACT_APP_API_URL + "/mouvement_history/produit_transforme/" + produitTrans.uuid}
        aria-label="Exporter l'historique des mouvements"
        title="Historique des mouvements"
        className={classes.button}
      >
           <WhereToVote/>
      </IconButton>
    );
  }
  }



  onChangeIncompletHandler(onChangeHandler, name) {
    const {
      entameCode,
      incompletCode,
      produits_origines_code,
      produitsOriginesStore,
      produitsTransformesStore,
    } = this.props;
    const { entame, incomplet } = this.state;
    // Dans un premier temps le state du collection crud pour éviter un refresh inutile
    const other = name === "incomplet" ? "entame" : "incomplet";
    const value = name === "incomplet" ? incomplet : entame;
    onChangeHandler(name, {
      target: {
        value: !value,
      },
    });
    if (!value) {
      onChangeHandler(other, {
        target: {
          value: false,
        },
      });
    }
    // Maj du state local
    const nameCode = name === "incomplet" ? incompletCode : entameCode;
    const code = !value ? nameCode : produits_origines_code;
    this.setState({
      [name]: !value,
      [other]: false,
      produits_origines_uuid: produitsOriginesStore.list.find(
        (origine) => origine.code === code
      ).uuid,
    });
    if (
      produitsTransformesStore &&
      produitsTransformesStore.detail &&
      produitsTransformesStore.detail.reference
    ) {
      const reference = produitsTransformesStore.detail.reference;
      if (
        new RegExp(
          `^(${ORIGINES_PREFIX.ENTAME}|${ORIGINES_PREFIX.SILO}|${ORIGINES_PREFIX.SILO_INCOMPLET})-`
        ).test(reference)
      ) {
        // Met à jour le préfixe de la référence pour rester cohérent avec
        // Origine, Entamé ou Incomplet
        const namePrefix =
          name === "incomplet"
            ? ORIGINES_PREFIX.SILO_INCOMPLET
            : ORIGINES_PREFIX.ENTAME;
        const prefix = !value ? namePrefix : ORIGINES_PREFIX.SILO;
        onChangeHandler("reference", {
          target: {
            value: reference.replace(/^[^-]+-/, prefix + "-"),
          },
        });
      }
    }
  }
  getOptionLibelle(key, row) {
    /* eslint-disable-next-line */
    const option = this.getOptionsFromState(key).find(
      // Volontairement double égale pour éviter les soucis de type de données
      /* eslint-disable-next-line */
      (o) => o.value == row[key]
    );
    return option ? option.libelle : "Aucun";
  }
  getOptionsFromState(option) {
    if (!this.state.options[option]) return [];
    return this.state.options[option];
  }
  getOptions(option) {
    if (!this.state.options[option]) return [];
    return this.getOptionsFromState(option).map((o) => {
      return (
        <MenuItem key={o.value} value={o.value}>
          {o.libelle}
        </MenuItem>
      );
    });
  }

  getBBEntamesOptions(produitTransforme) {
    const { produit_transforme_uuid } = this.state;
    if (
      this.state.bb_entames === null ||
      produitTransforme?.uuid !== produit_transforme_uuid
    ) {
      // default value when loading
      // eslint-disable-next-line
      this.state.bb_entames = [];
      // eslint-disable-next-line
      this.state.produit_transforme_uuid = produitTransforme?.uuid;
      axiosClient
        .get(
          `/stock/produits_transformes/entames?produit_transforme_uuid=${
            produitTransforme?.uuid || null
          }`
        )
        .then((response) => {
          this.setState({
            bb_entames: response.data,
          });
        });
    }

    return [
      {
        uuid: null,
        reference: "Aucun",
      },
    ]
      .concat(this.state.bb_entames)
      .map((bb) => {
        return (
          <MenuItem key={bb.uuid} value={bb.uuid}>
            {bb.reference}
          </MenuItem>
        );
      });
  }

  getPirPcrs() {
    if (!this.props.pirPcrsStore.list) {
      return [];
    }
    return this.props.pirPcrsStore.list.map((pir) => {
      return (
        <MenuItem value={pir.uuid} key={pir.uuid}>
          {pir.libelle}
        </MenuItem>
      );
    });
  }

  getCreateUpdateModalContent(
    produitTransforme,
    produitsTransformesStore,
    onChangeHandler
  ) {
    const { classes, user, readOnly, allowFromBBEntame } = this.props,
      ITEM_HEIGHT = 48,
      ITEM_PADDING_TOP = 8,
      MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      };

    if (
      produitTransforme.hasOwnProperty("reference") &&
      !produitTransforme.hasOwnProperty("incomplet")
    ) {
      const { incompletCode } = this.props;
      if (incompletCode !== undefined && produitTransforme.reference) {
        produitTransforme.incomplet =
          produitTransforme.reference.substring(0, 2) ===
          ORIGINES_PREFIX.SILO_INCOMPLET;

        if (this.state.incomplet !== produitTransforme.incomplet) {
          this.onChangeIncompletHandler(onChangeHandler, "incomplet");
        }
      }
    }

    var is_sl_produit = false;
    var is_disabled_reference = false;

    const { getReference, incompletCode, lastProduitSortie, sortieSilolist } =
      this.props;
    if (getReference && sortieSilolist) {
      is_sl_produit = true;
      if (!produitTransforme.reference) {
        produitTransforme.reference = getReference();
      }
      if (
        !this.state.produits_origines_uuid &&
        this.props.produits_origines_code &&
        this.props.produitsOriginesStore &&
        this.props.produitsOriginesStore.list
      ) {
        this.setState({
          produits_origines_uuid: this.props.produitsOriginesStore.list.find(
            (origine) => origine.code === this.props.produits_origines_code
          ).uuid,
          produits_origines_disabled: true,
        });
      }
      if (!produitTransforme.article_uuid && this.props.article_uuid) {
        produitTransforme.article_uuid = this.props.article_uuid;
        this.setState({
          famille_uuid: this.props.famille_uuid,
        });
      }
      if (!produitTransforme.date_transformation) {
        produitTransforme.date_transformation = this.props.dateTransformation
          ? this.props.dateTransformation
          : new Date();
      }
    }

    if (lastProduitSortie) {
      produitTransforme = {
        ...lastProduitSortie,
        ...produitTransforme,
      };
    }

    //Gestion de la date de transformation pour les utilisateur non admin bigbag
    if (
      !hasRights("admin-stock", user) &&
      !produitTransforme.date_transformation
    ) {
      produitTransforme.date_transformation = new Date();
    }

    // definir le pir_pcr_uuid par defaut si en création
    // if (!produitTransforme.uuid && !produitTransforme.pir_pcr_uuid) {
    //   produitTransforme.pir_pcr_uuid = this.state.defaultValues.pir_pcr_uuid;
    // }

    let {
        entame,
        famille_uuid,
        incomplet,
        produits_origines_disabled,
        produits_origines_uuid,
        usersList,
        users_uuids,
      } = this.state,
      {
        article_uuid,
        date_transformation,
        poids,
        reference,
        valide,
        produit_transforme_entame_uuid,
        pollution,
        flocage,
        taux_pollution,
        poussiere,
        taille_grille,
        mfi,
        humidite,
        separateur_metaux,
        commentaire,
        commentaire_interne,
        commentaire_cariste,
        comm_cariste_traite,
        pir_pcr_uuid,
      } = produitTransforme;

    const origine_produit = this.props.produitsOriginesStore.list.find(
      (origin) => origin.uuid === produits_origines_uuid
    );
    is_disabled_reference = origine_produit && origine_produit.autoincrement;

    return (
      <Grid container>
        <Grid item xs={3}>
          <TextInput
            id="produits_origines_uuid"
            label="Origine"
            value={produits_origines_uuid}
            disabled={produits_origines_disabled || readOnly}
            required
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="produits_origines_uuid"
            onChangeHandler={(name, event) => {
              if (!is_sl_produit) {
                const origine = this.props.produitsOriginesStore.list.find(
                  (origin) => origin.uuid === event.target.value
                );
                if (origine) {
                  const reference = getProduitTransformeReference(
                    this.props.dispatch,
                    origine.uuid,
                    {}
                  );
                  onChangeHandler("reference", {
                    target: { value: reference },
                  });
                }
              }
              this.onArticleChangeHandler(name, event);
            }}
            select
            fullWidth
          >
            {this.getOrigines()}
          </TextInput>
        </Grid>
        <Grid item xs={7}>
          <TextInput
            id="reference"
            label="Référence"
            value={reference}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="reference"
            onChangeHandler={onChangeHandler}
            fullWidth
            required
            disabled={
              is_disabled_reference ||
              getReference ||
              produits_origines_uuid === null ||
              readOnly
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="poids"
            label="Poids (kg)"
            value={poids}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="poids"
            type="number"
            onChangeHandler={onChangeHandler}
            fullWidth
            required
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={3}>
            <DateInput
              id="date_transformation"
              label="Date de transformation"
              value={date_transformation}
              margin="normal"
              collectionStore={produitsTransformesStore}
              name="date_transformation"
              onChangeHandler={onChangeHandler}
              onKeyPress={this.onKeyPressDateHandler.bind(
                this,
                onChangeHandler
              )}
              fullWidth
              pickertype="date"
              format={"d MMMM yyyy"}
              required
              disabled={readOnly}
            />
          </Grid>
          {allowFromBBEntame ? (
            <Grid item xs={3}>
              <TextInput
                id="produit_transforme_entame_uuid"
                label="Depuis un BB entamé"
                value={produit_transforme_entame_uuid}
                margin="normal"
                collectionStore={produitsTransformesStore}
                name="produit_transforme_entame_uuid"
                onChangeHandler={onChangeHandler}
                select
                fullWidth
                disabled={readOnly}
              >
                {this.getBBEntamesOptions(produitTransforme)}
              </TextInput>
            </Grid>
          ) : null}
          {incompletCode ? (
            <>
              <Grid item xs={3}>
                <Grid className={classes.incomplet}>
                  <CheckboxInput
                    id="incomplet"
                    label="Incomplet siloté"
                    value={incomplet}
                    margin="normal"
                    name="incomplet"
                    onChangeHandler={this.onChangeIncompletHandler.bind(
                      this,
                      onChangeHandler
                    )}
                    disabled={readOnly}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid className={classes.incomplet}>
                  <CheckboxInput
                    id="entame"
                    label="Incomplet non siloté"
                    value={entame}
                    margin="normal"
                    name="entame"
                    onChangeHandler={this.onChangeIncompletHandler.bind(
                      this,
                      onChangeHandler
                    )}
                    disabled={readOnly}
                  />
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="famille_uuid"
            label="Famille *"
            value={famille_uuid}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="famille_uuid"
            onChangeHandler={this.onArticleChangeHandler.bind(this)}
            select
            fullWidth
            disabled={readOnly}
          >
            {this.getFamilles()}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="article_uuid"
            label="Article"
            value={article_uuid}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="article_uuid"
            onChangeHandler={(name, event) => {
              onChangeHandler("produit_transforme_entame_uuid", {
                target: { value: null },
              });
              onChangeHandler(name, event);
            }}
            disabled={!famille_uuid || readOnly}
            required
            fullWidth
            select
          >
            {this.getArticles(famille_uuid)}
          </TextInput>
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="pir_pcr_uuid"
            label="PIR/PCR"
            value={pir_pcr_uuid}
            className={classes.selectContainer}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="pir_pcr_uuid"
            onChangeHandler={onChangeHandler}
            select
            required
            disabled={readOnly}
          >
            {this.getPirPcrs()}
          </TextInput>
        </Grid>
        <Grid item xs={12} className={classes.divider}>
          <Divider />
        </Grid>
        <Grid item xs={5}>
          <FormControl
            className={classes.formControl}
            error={
              !!produitsTransformesStore.errors &&
              !!produitsTransformesStore.errors["users_uuids"]
            }
            required
          >
            <InputLabel htmlFor="select-multiple-checkbox">
              Opérateurs
            </InputLabel>
            <Select
              multiple
              value={users_uuids}
              onChange={this.onChangeMultipleSelectHandler.bind(this)}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={(selected) =>
                selected.map((s) => {
                  let user = usersList.find((u) => u.uuid === s);
                  if (!user) return null;

                  return (
                    <Chip
                      key={user.uuid}
                      variant="outlined"
                      onDelete={this.handleDeleteChip.bind(
                        this,
                        "users_uuids",
                        user.uuid
                      )}
                      label={user.firstname + " " + user.lastname}
                      avatar={
                        <Avatar className={classes.avatar}>
                          {(
                            user.firstname.charAt(0) + user.lastname.charAt(0)
                          ).toUpperCase()}
                        </Avatar>
                      }
                      className={classes.chips}
                    />
                  );
                })
              }
              MenuProps={MenuProps}
              disabled={readOnly}
            >
              {usersList.map((user) => {
                let checked = undefined;
                if (users_uuids)
                  checked = users_uuids.find((userUuid) => {
                    return userUuid === user.uuid;
                  });

                return (
                  <MenuItem key={user.uuid} value={user.uuid}>
                    <Checkbox checked={checked !== undefined} color="primary" />
                    <ListItemText
                      primary={user.firstname + " " + user.lastname}
                    />
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              {!!produitsTransformesStore.errors &&
              !!produitsTransformesStore.errors["users_uuids"]
                ? produitsTransformesStore.errors["users_uuids"]
                : null}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <CheckboxInput
            id="separateur_metaux"
            value={separateur_metaux}
            margin="normal"
            name="separateur_metaux"
            label="Séparateur de métaux"
            formControlStyle={{ marginTop: "20px", marginLeft: "10px" }}
            onChangeHandler={onChangeHandler}
            disabled={readOnly}
          />
        </Grid>

        <Grid item xs={3}>
          <TextInput
            id="humidite"
            label="Humidité"
            value={humidite}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="humidite"
            onChangeHandler={onChangeHandler}
            select
            fullWidth
            disabled={readOnly}
          >
            {this.getOptions("humidite")}
          </TextInput>
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="flocage"
            label="Flocage"
            value={flocage}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="flocage"
            onChangeHandler={onChangeHandler}
            select
            fullWidth
            disabled={readOnly}
          >
            {this.getOptions("flocage")}
          </TextInput>
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="pollution"
            label="Pollution"
            value={pollution}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="pollution"
            onChangeHandler={onChangeHandler}
            select
            fullWidth
            disabled={readOnly}
          >
            {this.getOptions("pollution")}
          </TextInput>
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="taux_pollution"
            label="Taux de Pollution"
            value={taux_pollution}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="taux_pollution"
            onChangeHandler={onChangeHandler}
            select
            fullWidth
            disabled={readOnly}
          >
            {this.getOptions("taux_pollution")}
          </TextInput>
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="poussiere"
            label="Poussière"
            value={poussiere}
            required
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="poussiere"
            onChangeHandler={onChangeHandler}
            select
            fullWidth
            disabled={readOnly}
          >
            {this.getOptions("poussiere")}
          </TextInput>
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="taille_grille"
            label="Taille Grille"
            value={taille_grille}
            required
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="taille_grille"
            onChangeHandler={onChangeHandler}
            select
            fullWidth
            disabled={readOnly}
          >
            {this.getOptions("taille_grille")}
          </TextInput>
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="mfi"
            label="MFI"
            value={mfi}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="mfi"
            onChangeHandler={onChangeHandler}
            fullWidth
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaire"
            label="Commentaire Client (60 caractères max)"
            inputProps={{ maxLength: 60 }}
            value={commentaire}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="commentaire"
            onChangeHandler={onChangeHandler}
            fullWidth
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="commentaire_interne"
            label="Commentaire Interne"
            value={commentaire_interne}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="commentaire_interne"
            onChangeHandler={onChangeHandler}
            fullWidth
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={10}>
          <TextInput
            id="commentaire_cariste"
            label="Commentaire Cariste"
            value={commentaire_cariste}
            margin="normal"
            collectionStore={produitsTransformesStore}
            name="commentaire_cariste"
            onChangeHandler={onChangeHandler}
            fullWidth
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={2}>
          <CheckboxInput
            id="comm_cariste_traite"
            value={comm_cariste_traite}
            margin="normal"
            name="comm_cariste_traite"
            label="Traité"
            formControlStyle={{ marginTop: "20px", marginLeft: "10px" }}
            onChangeHandler={onChangeHandler}
            disabled={readOnly}
          />
        </Grid>

        {this.props.validate_bb ? (
          <Grid item xs={3}>
            <Grid className={classes.incomplet}>
              <CheckboxInput
                id="valide"
                label="Bigbag Valide"
                value={valide}
                margin="normal"
                name="valide"
                onChangeHandler={onChangeHandler}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        ) : null}
        {!produitTransforme.uuid ? (
          <Grid item xs={4} className={classes.autoPrint}>
            <CheckboxInput
              id="auto_print"
              value={this.state.autoPrint}
              margin="normal"
              name="Imprimer l'étiquette"
              label="Imprimer l'étiquette"
              formControlStyle={{ marginTop: "20px" }}
              onChangeHandler={this.onChangeAutoPrint.bind(this)}
              disabled={readOnly}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }

  getCrudTitle(title) {
    const { classes } = this.props;
    return (
      <Typography variant="button" gutterBottom className={classes.crudTitle}>
        {title}
      </Typography>
    );
  }

  getExtraButtons() {
    const { classes, user, hideBtnEtatStock } = this.props;
    if (!hasRights("admin-stock", user) || hideBtnEtatStock) return null;

    return (
      <>
        <Fab
          title="État des produits transformés"
          size="small"
          color="primary"
          aria-label="add"
          className={classes.statsButton}
          onClick={() => this.setState({ openStats: true })}
          style={{ fontSize: 32 }}
        >
          <BarChart />
        </Fab>
      </>
    );
  }

  closeStatsCallback() {
    this.setState({ openStats: false });
  }

  getEtat() {
    if (this.state.etat)
      return (
        <ProduitTransformeEtat
          open={this.state.openStats}
          onClose={this.closeStatsCallback.bind(this)}
          data={this.state.etat}
        />
      );
  }

  getReference(produit) {
    const { classes } = this.props;
    let result = [];

    result.push(produit.reference);
    if (produit.duplicate)
      result.push(
        <Warning key={produit.reference} className={classes.warning} />
      );

    return result;
  }

  onChangeAutoPrint() {
    this.setState((prevState) => ({
      autoPrint: !prevState.autoPrint,
    }));
  }

  onCloseHandlerModal() {
    this.setState({
      openModal: false,
      selectedPoidsConsomme: 0,
    });
  }

  onChangeMatierePremiereHandler(event) {
    this.setState({
      selectedPoidsConsomme: event.target.value,
    });
  }

  onChangeHandlerFilters(name, e) {
    var value = e.target.value;
    var force_page_1 = false;
    if (Array.isArray(value)) {
      if (value.length > 0) {
        if (this.state[name].indexOf("all") !== -1) {
          value = value.filter((v) => v !== "all");
        } else if (value.indexOf("all") !== -1) {
          value = ["all"];
        }
      } else {
        value = ["all"];
      }

      force_page_1 = true;
    }
    // page
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.loadAsyncData(this.state.local_async_filter, force_page_1);
      }
    );
  }

  getFilters() {
    const { classes } = this.props;
    const {
      filterReserved,
      origines_filtre,
      origine_groupe_filtre,
      filter_stockage_exertieur,
      famille_filtre,
      filter_commentaire_carriste,
    } = this.state;

    const reservedTypes = [
      {
        value: "all",
        libelle: "Tous",
      },
      {
        value: "none",
        libelle: "Non Réservé",
      },
      {
        value: "silo",
        libelle: "Réservé silolist",
      },
      {
        value: "packing",
        libelle: "Packinglist Tous",
      },
      {
        value: "packing_dispo",
        libelle: "Packinglist dispo",
      },
      {
        value: "packing_reserver",
        libelle: "Packing List Réservé",
      },
    ];

    const options = reservedTypes.map((reservedType) => {
      return (
        <MenuItem value={reservedType.value} key={reservedType.value}>
          {reservedType.libelle}
        </MenuItem>
      );
    });

    const options_origine_groupe_filtre = origines_filtre.map((origine) => {
      return (
        <MenuItem value={origine} key={origine}>
          {origine}
        </MenuItem>
      );
    });

    const options_famille_filtre = this.props.famillesStore.list?.map(
      (famille) => {
        return (
          <MenuItem value={famille.uuid} key={famille.uuid}>
            {famille.nom}
          </MenuItem>
        );
      }
    );
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="filterReserved"
            label="Type Réservation"
            margin="normal"
            onChange={this.onChangeHandlerFilters.bind(this, "filterReserved")}
            className={classes.selectContainer}
            select
            SelectProps={{
              multiple: true,
              value: filterReserved,
            }}
          >
            {options}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="origine_groupe_filtre"
            label="Groupe Origine"
            margin="normal"
            onChange={this.onChangeHandlerFilters.bind(
              this,
              "origine_groupe_filtre"
            )}
            className={classes.selectContainer}
            select
            SelectProps={{
              multiple: true,
              value: origine_groupe_filtre,
            }}
          >
            <MenuItem value={"all"} key={"all"}>
              Toutes
            </MenuItem>
            {options_origine_groupe_filtre}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="famille_filtre"
            label="Famille"
            margin="normal"
            onChange={this.onChangeHandlerFilters.bind(this, "famille_filtre")}
            className={classes.selectContainer}
            select
            SelectProps={{
              multiple: true,
              value: famille_filtre,
            }}
          >
            <MenuItem value={"all"} key={"all"}>
              Toutes
            </MenuItem>
            {options_famille_filtre}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="filter_stockage_exertieur"
            label="Stockage Extérieur"
            value={filter_stockage_exertieur}
            margin="normal"
            onChange={this.onChangeHandlerFilters.bind(
              this,
              "filter_stockage_exertieur"
            )}
            className={classes.selectContainer}
            select
          >
            <MenuItem value={"all"} key={"all"}>
              Tous
            </MenuItem>
            <MenuItem value={"oui"} key={"oui"}>
              Oui
            </MenuItem>
            <MenuItem value={"non"} key={"non"}>
              Non
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="filter_commentaire_carriste"
            label="Commentaire Cariste"
            value={filter_commentaire_carriste}
            margin="normal"
            onChange={this.onChangeHandlerFilters.bind(
              this,
              "filter_commentaire_carriste"
            )}
            className={classes.selectContainer}
            select
          >
            <MenuItem value={"all"} key={"all"}>
              Tous
            </MenuItem>
            <MenuItem value={"traite"} key={"traite"}>
              Traité
            </MenuItem>
            <MenuItem value={"non_traite"} key={"non_traite"}>
              Non traité
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>
    );
  }

  getData() {
    if (this.props.hasOwnProperty("datas")) {
      return this.props.datas;
    } else {
      return null;
    }
  }

  getBtnMasseImport() {
    const { user, readOnly, hideBtnMassImport } = this.props;

    if (
      !readOnly &&
      hasRights("admin-masse-import-bb", user) &&
      !hideBtnMassImport
    ) {
      return (
        <Grid container>
          <Grid item xs={10} />
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              title="Importation de masse"
              onClick={() => {
                document.getElementById("file").click();
              }}
            >
              <FileCopy /> Importer
            </Button>
            <input
              type="file"
              id="file"
              style={{ display: "none" }}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={this.importMasse.bind(this)}
            />
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  }

  importMasse(event) {
    const file = event.target.files[0];
    if (file) {
      masseImportBigBags(file)
        .then((response) => {
          addNotification(this.props.dispatch, {
            message: response.data.message,
            bgColor: themeComplement.palette.notifications.success.color,
          });
        })
        .catch((error) => {
          axiosErrorHandler(this.props.dispatch, error);
          // reset file input
          document.getElementById("file").value = "";
        });
    }
  }

  loadAsyncData(filters, force_page_1) {
    const {
      dispatch,
      produitsTransformesStore,
      validate_bb,
      out_of_stock,
      stock_bb,
    } = this.props;

    if (produitsTransformesStore.fetching) return;

    const {
      filterReserved,
      origine_groupe_filtre,
      filter_stockage_exertieur,
      famille_filtre,
      filter_commentaire_carriste,
    } = this.state;

    let local_filter = {
      params: {
        stock_bb: stock_bb,
        out_of_stock: out_of_stock,
        to_validate: validate_bb,
        filterReserved,
        origine_groupe_filtre,
        filter_stockage_exertieur,
        filter_commentaire_carriste,
        famille_filtre,
        ...filters,
        page: force_page_1 ? 1 : filters.page,
      },
    };

    if (force_page_1) {
      this.ccrudProduitTransforme.setDatatablePage(1);
    }

    // eslint-disable-next-line
    this.state.local_async_filter = { ...filters };

    return collectionActions(
      dispatch,
      "stock/produits_transformes",
      "INDEX",
      local_filter,
      null,
      true
    );
  }
  remiseEnStock(uuid) {
    remiseEnStockBigBag(this.props.dispatch, uuid)
      .then(() => {
        this.loadAsyncData(this.state.local_async_filter, true);
        addNotification(this.props.dispatch, {
          message:
            "Le big bag a été remis en stock, vous le retrouverez dans la zone de restockage",
          bgColor: themeComplement.palette.notifications.success.color,
        });
      })
      .catch((error) => {
        errorHandlerCollectionCrud(
          this.props.dispatch,
          error,
          "stock/produits_transformes"
        );
      });
  }
  getBtnRemiseEnStock(row) {
    const { out_of_stock, classes, user } = this.props;
    if (
      !out_of_stock ||
      !row.valide ||
      !hasRights(["admin-bb-remise-en-stock"], user)
    )
      return null;

    return (
      <IconButton
        key="remise-en-stock"
        onClick={() => {
          this.remiseEnStock(row.uuid);
        }}
        aria-label="Remise en stock"
        title="Remise en stock"
        className={classes.button}
      >
        <Restore />
      </IconButton>
    );
  }
  getBtnValidate(row) {
    const { validate_bb, classes, dispatch } = this.props;
    if (!validate_bb || row.valide) return null;

    return (
      <IconButton
        key="validate-bb"
        onClick={() => {
          validateBigBag(dispatch, row.uuid, {
            valide: true,
          });
        }}
        aria-label="Valider le big bag"
        title="Valider le big bag"
        className={classes.button}
      >
        <Check />
      </IconButton>
    );
  }

  getBtnDocuments(row) {
    const { classes } = this.props;
    return (
      <DocumentPicker
        documentable_uuid={row.uuid}
        documentable_type="App\Models\ProduitTransforme"
        className={classes.button}
        hasDocument={row.has_document}
      />
    );
  }

  getDefaultValuesCallback() {
    const { defaultValues, sortieSilolist } = this.state;
    const allowedKeys = ["separateur_metaux", "flocage"];
    if (sortieSilolist) {
      allowedKeys.push(
        "humidite",
        "pollution",
        "taux_pollution",
        "poussiere",
        "taille_grille",
        "mfi",
        "commentaire",
        "commentaire_interne"
      );
    }
    return Object.keys(defaultValues).reduce((acc, key) => {
      if (allowedKeys.includes(key)) {
        acc[key] = defaultValues[key];
      }
      return acc;
    }, {});
  }

  render() {
    const {
      actionsCallback,
      classes,
      columns,
      createUpdateModalGetNextState,
      getReference,
      pagination,
      search,
      showListButtons,
      title,
      user,
      validate_bb,
      out_of_stock,
      hideDeleteBtn,
      readOnly,
    } = this.props;

    return (
      <>
        <Grid container className={classes.container}>
          {getReference ? null : this.getBtnMasseImport()}
          {getReference ? null : this.getFilters()}
          <CollectionCrud
            innerRef={(el) => (this.ccrudProduitTransforme = el)}
            asynchrone={!this.props.hasOwnProperty("datas")}
            datas={this.getData()}
            loadDatas={!this.props.hasOwnProperty("datas")}
            asynchroneCallbackLoadData={this.loadAsyncData.bind(this)}
            modalMaxWidth="md"
            collectionName={"stock/produits_transformes"}
            cancelUpdateCheck={true}
            showBtnAdd={showListButtons !== false && !readOnly}
            showBtnDelete={showListButtons !== false && !hideDeleteBtn}
            showBtnEdit={showListButtons !== false}
            createUpdateModalSubmit={readOnly ? "NONE" : "Valider"}
            rights={{
              delete: "admin-d-bigbags",
            }}
            showBtnEditCallBack={(produit_transformer) => {
              if (hasRights("admin-stock", user)) return true;
              else return !produit_transformer.valide;
            }}
            showBtnCreateCallBack={() => {
              return (
                hasRights("admin-stock", user) ||
                hasRights("admin-c-bigbags", user)
              );
            }}
            deleteModalTitle={this.getDeleteModalTitle}
            deleteModalContent={this.getDeleteModalContent}
            createUpdateModalTitle={this.getCreateUpdateModalTitle}
            createUpdateModalContent={this.getCreateUpdateModalContent.bind(
              this
            )}
            createUpdateModalNext={getReference ? "Suivant" : undefined}
            createUpdateModalGetNextState={createUpdateModalGetNextState}
            actionsCallback={(action, detail, duplicate) => {
              if (
                action === "create" ||
                action === "update" ||
                action === "delete"
              ) {
                getProduitsTransformesEtat().then((etat) => {
                  this.setState({ etat: etat });
                });
                if ((validate_bb || out_of_stock) && this.props.refreshList) {
                  this.props.refreshList();
                }
              }
              if (duplicate && (action === "create" || action === "update")) {
                // Duplication : persiste les champs dans le formulaire
                this.setState({
                  famille_uuid: detail.article_famille_uuid,
                  produits_origines_uuid: detail.origine.uuid,
                  users_uuids: detail.users_uuids,
                  entame: false,
                  incomplet: false,
                });
              }

              if (action === "create" || action === "update") {
                if (this.props.sortieSilolist) {
                  this.setState({
                    defaultValues: {
                      separateur_metaux: detail.separateur_metaux,
                      humidite: detail.humidite,
                      pollution: detail.pollution,
                      taux_pollution: detail.taux_pollution,
                      poussiere: detail.poussiere,
                      taille_grille: detail.taille_grille,
                      flocage: detail.flocage,
                      mfi: detail.mfi,
                      commentaire: detail.commentaire,
                      commentaire_interne: detail.commentaire_interne,
                    },
                  });
                } else {
                  this.setState({
                    defaultValues: {
                      flocage: "CAPECO",
                      pir_pcr_uuid: this.state.defaultValues.pir_pcr_uuid,
                    },
                  });
                }
              }
              if (actionsCallback) {
                actionsCallback(action, detail, duplicate);
              }
              if (this.state.autoPrint && action === "create") {
                window.open(
                  buildRoute(
                    getApiUrl() + "/stock/produits_transformes/etiquette",
                    {
                      listUuids: JSON.stringify([detail.uuid]),
                    }
                  ),
                  "_blank"
                );
              }
              // initialise l'autoprint à l'ouverture de la modale
              if (action === "click-add") {
                this.setState({ autoPrint: false });
              }

              if (action === "close") {
                this.setState({
                  bb_entames: null,
                  produit_transforme_uuid: null,
                });
              }
            }}
            datatableConfig={{
              showPagination: pagination === undefined ? true : pagination,
              showSearch: search === undefined ? true : search,
              defaultSort: "reference",
              sortType: "desc",
              rowsPerPageOptions: [25, 50],
              filterCallback: (conf, datas, order) => {
                if (conf) {
                  return datas.sort((a, b) => {
                    let valueA = a[conf.datakey],
                      valueB = b[conf.datakey];

                    if (conf.isDate) {
                      valueA = new Date(valueA).getTime();
                      valueB = new Date(valueB).getTime();
                    }
                    if (valueA || valueB) {
                      valueA = valueA ?? "";
                      valueB = valueB ?? "";
                    }

                    if (conf.isBoolean) {
                      if (order === "asc")
                        return valueA === valueB ? 0 : valueA ? -1 : 1;
                      if (order !== "asc")
                        return valueA === valueB ? 0 : valueA ? 1 : -1;
                    } else {
                      if (valueA < valueB) return order === "asc" ? 1 : -1;
                      if (valueA > valueB) return order === "asc" ? -1 : 1;
                      return 0;
                    }
                    return 0;
                  });
                }

                return datas;
              },
              rowClassName: (produit) => {
                let className = validate_bb ? classes.rowBB : "";
                if (produit.packinglist) {
                  if (produit.packinglist.statut === "stockage-ext") {
                    className += " " + classes.packinglist_stockage_ext;
                  } else {
                    const isIReserverDisponible =
                      produit.statut === "reserver_disponible";
                    const class_reserver_disponible = isIReserverDisponible
                      ? " " + classes.packinglist_disponible
                      : "";
                    className +=
                      " " +
                      (isIReserverDisponible
                        ? class_reserver_disponible
                        : classes.packinglist);
                  }
                }
                if (
                  produit.silolist &&
                  produit.silolist.pivot.entree_sortie === "entree"
                )
                  className += " " + classes.silolist;

                return className;
              },
            }}
            cellsConfig={this.getCellsConfig(columns)}
            additionnalControllers={[
              this.getPrintEtiquetteButton.bind(this),
              this.getLogButton.bind(this),
              this.getBtnValidate.bind(this),
              this.getBtnRemiseEnStock.bind(this),
            ]}
            defaultValuesCallback={this.getDefaultValuesCallback.bind(this)}
            extradatasForm={{
              quantite: 1,
              users_uuids: this.state.users_uuids,
              produits_origines_uuid: this.state.produits_origines_uuid,
              auto_print: this.state.autoPrint,
            }}
            persistDatatableOptions={{
              id: "stock_produit_transforme",
              exipiration_minutes: 60 * 12, // 12 Heures
            }}
            dataTableExtraNodes={[
              {
                element: title
                  ? this.getCrudTitle(title)
                  : this.getExtraButtons(),
                position: "top-left",
              },
            ]}
          />
          {this.getEtat()}
        </Grid>
      </>
    );
  }
}

ProduitTransforme = withStyles(StockCss)(ProduitTransforme);

ProduitTransforme = connect((store) => {
  return {
    produitsTransformesStore: store.collections["stock/produits_transformes"],
    usersStore: store.collections.users,
    articlesStore: store.collections.articles,
    location: store.router.location,
    produitsOriginesStore: store.collections.produits_origines,
    famillesStore: store.collections.familles,
    user: store.auth.user,
    pirPcrsStore: store.collections["pir-pcrs"],
  };
})(ProduitTransforme);

export default ProduitTransforme;
