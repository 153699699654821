import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Check, Settings } from "@material-ui/icons";
import TextInput from "../../common/Components/TextInput";
import TypeDocumentCss from "./css/TypeDocumentCss";
import CollectionCrud from "../../common/Components/CollectionCrud";
import CheckboxInput from "../../common/Components/CheckboxInput";

class TypeDocument extends Component {
  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression du type de document"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer ce type de document ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(typedocument) {
    let title = !!typedocument
      ? "Modification du type de document : " + typedocument.libelle
      : "Ajout d'un nouveau type de document";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(
    typedocument,
    typedocumentsStore,
    onChangeHandler
  ) {
    let {
      libelle,
      type_photo,
      liasse_documentaire_reception,
      liasse_documentaire_expedition,
      is_lot_mad_negoce,
    } = typedocument;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="libelle"
            label="Libelle"
            value={libelle}
            margin="normal"
            collectionStore={typedocumentsStore}
            name="libelle"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="typePhoto"
            label="Type photo"
            value={type_photo}
            margin="normal"
            collectionStore={typedocumentsStore}
            name="type_photo"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="liasse_documentaire_reception"
            label="Liasse documentaire reception"
            value={liasse_documentaire_reception}
            margin="normal"
            collectionStore={typedocumentsStore}
            name="liasse_documentaire_reception"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="liasse_documentaire_expedition"
            label="Liasse documentaire expedition"
            value={liasse_documentaire_expedition}
            margin="normal"
            collectionStore={typedocumentsStore}
            name="liasse_documentaire_expedition"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxInput
            id="is_lot_mad_negoce"
            label="Documents lot mad negoce"
            value={is_lot_mad_negoce}
            margin="normal"
            collectionStore={typedocumentsStore}
            name="is_lot_mad_negoce"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"type_documents"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: "admin-cud-parametres",
            edit: "admin-cud-parametres",
            delete: "admin-cud-parametres",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "libelle",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          cellsConfig={[
            {
              datakey: "libelle",
              title: "Libelle",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "type_photo",
              title: "Type photo",
              sortable: true,
              searchable: true,
              format: (data, key) => (data[key] ? <Check /> : null),
            },
            {
              datakey: "liasse_documentaire_reception",
              title: "Liasse documentaire reception",
              sortable: true,
              searchable: true,
              format: (data, key) => (data[key] ? <Check /> : null),
            },
            {
              datakey: "liasse_documentaire_expedition",
              title: "Liasse documentaire expedition",
              sortable: true,
              searchable: true,
              format: (data, key) => (data[key] ? <Check /> : null),
            },
            {
              datakey: "is_lot_mad_negoce",
              title: "Documents lot mad negoce",
              sortable: true,
              searchable: true,
              format: (data, key) => (data[key] ? <Check /> : null),
            },
          ]}
        />
      </Grid>
    );
  }
}

TypeDocument = withStyles(TypeDocumentCss)(TypeDocument);

export default TypeDocument;
